import React from 'react';
import FundingCard from './FundingCard';

import Verizon_Small_Business_Grant from '../assets/Verizon_Small_Business_Grant.png'
import Amazon_Small_Business_Grant from '../assets/Amazon_Small_Business_Grant.png'
import Visa_Everywhere_Initiative from '../assets/Visa_Everywhere_Initiative.png'
import FedEx_Small_Business_Grant_Contest from '../assets/FedEx_Small_Business_Grant_Contest.png'
import American_Express_Backing_Small_Businesses from '../assets/American_Express_Backing_Small_Businesses.png'
import Venmo_Small_Business_Grant from '../assets/Venmo_Small_Business_Grant.png'

const cardsData = [
  {
    href: "/funding-details/verizon",
    imgSrc: Verizon_Small_Business_Grant,
    imgAlt: "Verizon Small Business Grant",
    title: "Verizon Small Business Grant",
    description: "$10,000 small business grant program with completion of 2 courses.",
    dueDate: "June 28"
  },
  {
    href: "/funding-details/amazon",
    imgSrc: Amazon_Small_Business_Grant,
    imgAlt: "Amazon Small Business Grant",
    title: "Amazon Small Business Grant",
    description: "$15,000-$25,000 grants to Amazon Business account and $1 million or less in annual revenue.",
    dueDate: "May 31"
  },
  {
    href: "/funding-details/visa",
    imgSrc: Visa_Everywhere_Initiative,
    imgAlt: "Visa Everywhere Initiative",
    title: "Visa Everywhere Initiative",
    description: "$100,000 grant to tech-forward startups across five different regions around the globe.",
    dueDate: "April 30"
  },
  {
    href: "/funding-details/fedex",
    imgSrc: FedEx_Small_Business_Grant_Contest,
    imgAlt: "FedEx Small Business Grant Contest",
    title: "FedEx Small Business Grant Contest",
    description: "Annual grant competition awards one $50,000 grand prize and nine additional $20,000 grants.",
    dueDate: "April 1"
  },
  {
    href: "/funding-details/americanexpress",
    imgSrc: American_Express_Backing_Small_Businesses,
    imgAlt: "American Express Backing Small Businesses",
    title: "American Express Backing Small Businesses",
    description: "Program awards $10,000 grants to 500 small businesses.",
    dueDate: "April 7"
  },
  {
    href: "/funding-details/venmo",
    imgSrc: Venmo_Small_Business_Grant,
    imgAlt: "Venmo Small Business Grant",
    title: "Venmo Small Business Grant",
    description: "Awards 20 eligible entrepreneurs with $10,000 grants for their small businesses.",
    dueDate: "May 31"
  }
];

function FundingCards() {
  return (
    <section className="relative mt-[3.75rem] h-[21.5rem] w-full max-w-[105rem] overflow-clip md:mt-[5rem] md:h-[22.5rem]">
      <div className="flex w-max animate-scroll-home flex-row gap-[1.16rem] delay-75 lg:gap-[1.875rem]">
        {cardsData.map((card, index) => (
          <FundingCard
            key={index}
            href={card.href}
            imgSrc={card.imgSrc}
            imgAlt={card.imgAlt}
            title={card.title}
            description={card.description}
            dueDate={card.dueDate}
          />
        ))}
      </div>
      <div className="hidden md:block">
        <div className="pointer-events-none absolute left-0 top-0 h-full w-20 bg-gradient-to-l from-transparent to-white"></div>
        <div className="pointer-events-none absolute right-0 top-0 h-full w-20 bg-gradient-to-r from-transparent to-white"></div>
      </div>
    </section>
  );
}

export default FundingCards;
