import React from 'react';
import Header from './components/Header';
import HeroSection from './sections/HeroSection';
import FundingCards from './components/FundingCards';
import HowItWorksSection from './sections/HowItWorksSection';
import Footer from './components/Footer';
import { ChakraProvider } from '@chakra-ui/react'

function App() {
  return (
    <ChakraProvider>
      <Header />
      <HeroSection />
      <FundingCards />
      <HowItWorksSection />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
