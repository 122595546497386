import React from 'react';
import { Box, Container, Text, VStack, HStack, Link, Icon, Stack } from '@chakra-ui/react';
import { FaInstagram, FaYoutube, FaLinkedin, FaFacebook, FaTiktok, FaTwitter, FaApple, FaGooglePlay } from 'react-icons/fa';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { GiHamburgerMenu } from 'react-icons/gi';
import { theme } from '../utils/themeDefaults';

function Footer() {
  return (
    <Box bg="white" py={12}>
      <Container maxW="container.lg">
        <Stack direction={{ base: 'column', md: 'row' }} spacing={10} justify="space-between">
          <VStack align="start" spacing={4}>
            <Text fontSize="lg" fontWeight="bold">About</Text>
            <Link href="#">About Us</Link>
            <Link href="#">Careers</Link>
            <Link href="#">Grants</Link>
            <Link href="#">Memberships</Link>
            <Link href="#">Grants Opportunities</Link>
            <Link href="#">Member Success Stories</Link>
          </VStack>
          <VStack align="start" spacing={4}>
            <Text fontSize="lg" fontWeight="bold">Resources</Text>
            <Link href="#">Blog</Link>
            <Link href="#">Partner With Us</Link>
            <Link href="#">Become an Affiliate</Link>
            <Link href="#">How to Get Grant Funding</Link>
            <Link href="#">See the Latest Grant Options</Link>
            <Link href="#">Search For Grant Funding</Link>
            <Link href="#">What's the App</Link>
            <Link href="#">Submit a Grant</Link>
          </VStack>
          <VStack align="start" spacing={4}>
            <Text fontSize="lg" fontWeight="bold">Support</Text>
            <Link href="#">Help Center</Link>
            <Link href="#">Contact Us</Link>
            <Text fontSize="lg" fontWeight="bold" mt={4}>Get PublicGrants App</Text>
            <HStack>
              <Link href="#"><Icon as={FaApple} boxSize={6} /></Link>
              <Link href="#"><Icon as={FaGooglePlay} boxSize={6} /></Link>
            </HStack>
          </VStack>
          <VStack align="start" spacing={4}>
            <Text fontSize="2xl" fontWeight="bold" color={theme.colors.primary}>PublicGrants</Text>
            <Text fontSize="md" fontWeight="bold">We are here to help you.</Text>
            <Text fontSize="lg" fontWeight="bold" mt={4}>Follow Us</Text>
            <HStack spacing={4}>
              <Link href="#"><Icon as={FaYoutube} boxSize={6} /></Link>
              <Link href="#"><Icon as={FaInstagram} boxSize={6} /></Link>
              <Link href="#"><Icon as={FaFacebook} boxSize={6} /></Link>
              <Link href="#"><Icon as={FaLinkedin} boxSize={6} /></Link>
              <Link href="#"><Icon as={FaTiktok} boxSize={6} /></Link>
              <Link href="#"><Icon as={FaTwitter} boxSize={6} /></Link>
            </HStack>
          </VStack>
        </Stack>
        <Box textAlign="center" mt={10}>
          <Text>Copyright &copy; 2024, PublicGrants.</Text>
          <HStack justify="center" spacing={4} mt={2}>
            <Link href="#">Terms & Conditions</Link>
            <Link href="#">Privacy & Policy</Link>
          </HStack>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
