import React from 'react';
import { Box, Container, Heading, Text, Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { theme } from '../utils/themeDefaults';

const textVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

const texts = [
  { word: "Businesses", color: theme.colors.primary },
  { word: "Individuals", color: theme.colors.primary },
  { word: "Non-Profits", color: theme.colors.primary },
];

function HeroSection() {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Change text every 2 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <Box bg="white" py={16} paddingTop={45}>
      <Container maxW="container.xl" textAlign="center">
        <Heading as="h1" size="3xl" fontWeight="bold" color="gray.800">
          Public Grants For{" "}
          <motion.span
            key={texts[index].word}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            style={{ display: "inline-block" }}
          >
            <Text as="span" color={texts[index].color}>
              {texts[index].word}
            </Text>
          </motion.span>
        </Heading>
        <Text color="gray.500" mt={4} fontSize='2xl'>
          NO Loans. NO Credit Cards. NO Cash Advances.
        </Text>
        <Button mt={8} bg={theme.colors.primary} color="white" px={8} py={3} rounded="full" _hover={{ bg: 'blue.700' }}>
          Get Started
        </Button>
      </Container>
    </Box>
  );
}

export default HeroSection;
