import { Box, Text, VStack, HStack, Image, useBreakpointValue, Icon } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons';
import DemoImage from '../assets/demo_image.png';

import FindGrantOpportunitiesImg from '../assets/find_grants_opportunities.png'
import CompleteApplicationsFasterImg from '../assets/complete_applications_faster.png'
import GetInstantFeedbackImg from '../assets/get_instant_feedback.png'
import OrLetUsApplyForYouImg from '../assets/let_us_apply.png'

const GrantOpportunities = () => {
  const imgSize = useBreakpointValue({ base: "150px", md: "200px" });

  return (
    <Box bg="#ffffff">
      <VStack spacing={0} align="center" position="relative">
        <Icon as={ChevronDownIcon} w={10} h={10} color="blue.500" mt={8} />
        <Box
          bg="#ffffff"
          borderRadius="full"
          px={4}
          py={2}
          boxShadow="sm"
          position="relative"
          top={-3}
          zIndex={1}
        >
          <Text fontSize="2xl" fontWeight="bold" color="#2D3748">
            HOW IT WORKS
          </Text>
        </Box>
      </VStack>
      <Box bg="#dae3f477" py={16} px={8}>
        <VStack spacing={12} align="start">
          <HStack spacing={8} align="start">
            <Image
              src={FindGrantOpportunitiesImg}
              alt="Find Grant Opportunities"
              borderRadius="md"
              w={imgSize}
              h={imgSize}
              objectFit="cover"
            />
            <VStack align="start" maxW="lg">
              <Text fontSize="2xl" fontWeight="bold" color="#2D3748">
                Find Grant Opportunities
              </Text>
              <Text fontSize="md" color="#4A5568">
                Discover grant opportunities and gain valuable insights on applying effectively. PublicGrants.org provides information and applications for grants only. We DO NOT promote other sources of funding. Why?
              </Text>
              <Text fontSize="md" color="#4A5568">
                Predatory lending hurts individuals and small businesses by promoting high interest loans to those searching for grant funding. Therefore we do not promote, partner or share your information with any loan service providers. We recommend individuals or businesses looking for a loan to contact their local bank, credit union or SBA.
              </Text>
            </VStack>
          </HStack>
          
          <HStack spacing={8} align="start" alignSelf="end">
            <VStack align="start" maxW="lg">
              <Text fontSize="2xl" fontWeight="bold" color="#2D3748">
                Complete Applications Faster
              </Text>
              <Text fontSize="md" color="#4A5568">
                Effortlessly write compelling applications using AI and save them securely in your personalized Grants Vault, keeping you organized and focused on winning funding.
              </Text>
            </VStack>
            <Image
              src={CompleteApplicationsFasterImg}
              alt="Complete Applications Faster"
              borderRadius="md"
              w={imgSize}
              h={imgSize}
              objectFit="cover"
            />
          </HStack>
          
          <HStack spacing={8} align="start">
            <Image
              src={GetInstantFeedbackImg}
              alt="Get Instant Feedback"
              borderRadius="md"
              w={imgSize}
              h={imgSize}
              objectFit="cover"
            />
            <VStack align="start" maxW="lg">
              <Text fontSize="2xl" fontWeight="bold" color="#2D3748">
                Get Instant Feedback
              </Text>
              <Text fontSize="md" color="#4A5568">
                Receive instant feedback to refine your funding application in real-time and boost your chances of securing the funding you need.
              </Text>
            </VStack>
          </HStack>
          
          <HStack spacing={8} align="start" alignSelf="end">
            <VStack align="start" maxW="lg">
              <Text fontSize="2xl" fontWeight="bold" color="#2D3748">
                Or Let Us Apply For You
              </Text>
              <Text fontSize="md" color="#4A5568">
                Save time by outsourcing your grant applications to us; we handle everything to maximize your chances of success.
              </Text>
            </VStack>
            <Image
              src={OrLetUsApplyForYouImg}
              alt="Or Let Us Apply For You"
              borderRadius="md"
              w={imgSize}
              h={imgSize}
              objectFit="cover"
            />
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default GrantOpportunities;
