import React from 'react';
import { Box, Container, Flex, Text, Link, Button, Divider, IconButton, VStack, HStack, Collapse, CloseButton, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { theme } from '../utils/themeDefaults';

const navLinks = [
  { href: "#", label: "Search Grants" },
  { href: "#", label: "Explore Memberships" },
  { href: "#", label: "Download APP" }
];

const NavLink = ({ href, label }) => (
  <Link
    href={href}
    color="black"
    fontSize="sm"
    fontWeight="medium"
    mx={2}
    _hover={{ textDecoration: "none" }}
  >
    {label}
  </Link>
);

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box as="header" bg="white" py={4} boxShadow="md">
      <Container maxW={{ base: "98%", xl: "90%", "2xl": "70%" }} display="flex" justifyContent="space-between" alignItems="center">
        <Text fontSize="2xl" fontWeight="bold" color={theme.colors.primary}>PublicGrants</Text>
        <Flex display={{ base: "none", xl: "flex" }} as="nav" alignItems="center">
          {navLinks.map((link, index) => (
            <NavLink key={index} href={link.href} label={link.label} />
          ))}
          <Divider orientation="vertical" height="30px" borderColor="gray.300" mx={2} />
          <Button
            variant="outline"
            borderColor={theme.colors.primary}
            color={theme.colors.primary}
            fontWeight="medium"
            mx={2}
            rounded="full"
          >
            Log In
          </Button>
          <Button
            backgroundColor={theme.colors.primary}
            colorScheme="none"
            fontWeight="medium"
            mx={2}
            rounded="full"
            color="white"
          >
            Sign Up Free
          </Button>
        </Flex>
        <IconButton
          display={{ base: "flex", xl: "none" }}
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          onClick={isOpen ? onClose : onOpen}
          fontSize={30}
          variant="ghost"
        />
      </Container>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: 'hidden' }}
          >
            <Box bg="white" pb={4} display={{ xl: 'none' }}>
              <VStack as="nav" spacing={4} alignItems="start">
                <HStack justifyContent="space-between" width="100%" px={4}>
                </HStack>
                {navLinks.map((link, index) => (
                  <NavLink key={index} href={link.href} label={link.label} />
                ))}
                <Divider borderColor="gray.300" />
                <Button
                  variant="outline"
                  borderColor={theme.colors.primary}
                  color={theme.colors.primary}
                  fontWeight="medium"
                  mx={4}
                  rounded="full"
                  width="calc(100% - 2rem)"
                >
                  Log In
                </Button>
                <Button
                  backgroundColor={theme.colors.primary}
                  colorScheme="none"
                  fontWeight="medium"
                  mx={4}
                  rounded="full"
                  color="white"
                  width="calc(100% - 2rem)"
                >
                  Sign Up Free
                </Button>
              </VStack>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Header;
