import React from 'react';

function FundingCard({ href, imgSrc, imgAlt, title, description, dueDate }) {
  return (
    <a className="inline-flex text-inherit" href={href}>
      <div className="flex w-[15em] flex-col overflow-clip rounded-[.75em] border border-neutral-100 bg-white text-inherit md:w-[16.25em]">
        <img
          alt={imgAlt}
          src={imgSrc}
          width="260"
          height="175"
          decoding="async"
          className="h-[10.125em] w-full select-none object-cover md:h-[10em]"
          loading="lazy"
          style={{ color: 'transparent' }}
        />
        <div className="flex flex-1 flex-col justify-between gap-[1em] border-t border-neutral-100 p-[.875em] md:p-[1em]">
          <div>
            <h6 className="text-center text-[.875em] font-semibold leading-tight md:text-[1em]">{title}</h6>
            <p className="mt-[.5em] line-clamp-2 text-center text-[.75em] text-gray-700">{description}</p>
          </div>
          <div className="flex w-full flex-col items-center rounded-[.5em] bg-primary-40 p-[.75em]">
            <div className="text-center text-[.75em] font-medium leading-[1.5] text-slate-400">Due date</div>
            <div className="text-center text-[.875em] font-medium leading-[1.5] md:text-[.9375em]">{dueDate}</div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default FundingCard;
